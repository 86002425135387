import * as React from 'react';
import styled from '@emotion/styled';
import ReactMarkdown from 'react-markdown';
import Helmet from 'react-helmet';
import Page from '../components/Page';
import Container from '../components/Container';
import IndexLayout from '../layouts';
import { widths } from '../styles/variables';
import Header from '../components/Header';
import LayoutMain from '../components/LayoutMain';
import seloImage from '../../assets/logo.png';
import breakerImage from '../../assets/breaker.jpg';

const Ender = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.background};
  padding: 16px;
  color: #fff;
`;

const Orange = styled.span`
  color: orange;
`;

const Breaker = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25vh;
  background: url(${props => props.image});
  background-size: 100% auto;
  div {
    width: 100%;
    max-width: ${widths.xl}px;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    h3 {
      color: #fff;
      font-size: 21px;
    }
  }
  h1 {
    color: #fff;
    font-size: 24px;
  }
`;

const Section = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  background-color: ${props => props.background};
  div {
    width: 100%;
    max-width: ${widths.xl}px;
    padding: 32px;
    margin-left: auto;
    margin-right: auto;
    ${({ sidebyside }) =>
    sidebyside &&
    `
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: ${widths.md}px) {
      flex-direction: column;
    }
    `}
  }
  article {
    flex: 1;
    min-height: 30vh;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 32px;
    h1 {
      flex: 1;
    }
    p {
      flex: 1;
    }
  }
  h1 {
    color: ${props => (props.fontcolor ? props.fontcolor : '#000')};
    font-size: 24px;
  }
  p {
    color: ${props => (props.fontcolor ? props.fontcolor : '#000')};
    font-size: 16px;
  }
`;

const Footer = styled(Section)`
  min-height: auto;
  padding: 0px;
  div {
    max-width: 100%;
    padding: 0px;
  }
  article {
    align-items: left;
    padding: 8px;
  }
  .img-footer {
    align-items: center;
  }
  img {
    width: 100px;
    height: auto;
  }
  a {
    color: #fff;
  }
`;

const Content = styled(Container)`
  background: #fff;
  width: 100% !important;
`;

const NoticiasWrapper = styled.div`
  display: flex !important;
  margin: 0 !important;
  padding: 0 !important;

  width: 100% !important;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  @media (max-width: ${widths.md + 100}px) {
    align-items: flex-start;
    justify-content: center;
  }
`;

const NoticiaCard = styled.div`
  margin: 8px !important;
  padding: 0px !important;

  @media (max-width: ${widths.md + 100}px) {
    width: calc(100% - 16px) !important;
    max-width: ${widths.md - 200}px !important;
    height: 150px !important;
  }

  @media (min-width: ${widths.md + 100}px) {
    width: calc(50% - 16px) !important;
    height: 150px !important;
  }

  .content {
    width: 100%;
    padding: 0px;
    margin: 0;
    background: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    // justify-content: space-between;

    div {
      padding: 0px;;
      margin: 0;
      flex: 0 1 33%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .title {
      background: #0F0006;
      p {
        color: #fff;
        font-size: 28px !important;
      }
    }

    .address {
      flex: 1 1;
      padding: 8px;
    }
  }

  p {
    margin: 0px;
  }
`;

const InstituicaoPage = ({ pageContext: { sobreSite } }) => {
  const logoUrl = sobreSite.Logo ? (sobreSite.Logo.url ? sobreSite.Logo.url : null) : null;
  const carrosselUrl = (sobreSite.Carrossel && sobreSite.Carrossel.length > 0) ? (sobreSite.Carrossel[0].url ? sobreSite.Carrossel[0].url : null) : null;

  return (
    <IndexLayout>
      <Helmet title='Loja Sinergia' />
      <LayoutMain>
        <Header logoImage={logoUrl} backgroundImg={sobreSite.Carrossel} />
        <Page>
          <Content>
            <Section background="#fafafa">
              <div>
                <h1>Maçonaria</h1>
                <ReactMarkdown source={sobreSite.FatosSobreMaconaria} />
              </div>
            </Section>

            <Section background="#f5f5f5">
              <div>
                <h1>Nossa loja</h1>
                <ReactMarkdown source={sobreSite.Historia} />
              </div>
            </Section>

            <Section background="#fafafa">
              <div>
                <h1>Gestão 2019/2021</h1>
                <ReactMarkdown source={sobreSite.ListaPastMasters} />
              </div>
            </Section>

            <Section background="#f5f5f5">
              <div>
                <h1>Links</h1>
                <ReactMarkdown source={sobreSite.OutrosLinks} />
              </div>
            </Section>

            <Footer background="#1f3447" fontcolor="#ffffff" sidebyside>
              <div>
                <article className="img-footer">
                  <img src={seloImage} />
                </article>
                <article>
                  <h1><Orange>→</Orange> E-mail</h1>
                  <p>
                    <a href="mailto:124sec@glojars.org.br">124sec@glojars.org.br</a>
                  </p>
                </article>
              </div>
            </Footer>
          </Content>
        </Page>
      </LayoutMain>
    </IndexLayout>
  );
};

export default InstituicaoPage;
